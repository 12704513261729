import { render, staticRenderFns } from "./PatientSettings.vue?vue&type=template&id=62f7f7b0&scoped=true"
import script from "./PatientSettings.vue?vue&type=script&lang=js"
export * from "./PatientSettings.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62f7f7b0",
  null
  
)

export default component.exports