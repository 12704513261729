<template>
  <v-dialog v-model="showDialog" fullscreen data-testid="edit-patient-id">
    <template #activator="{ on }">
      <v-btn
        v-if="isUserAllowedToManagePatient"
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
        :disabled="isPatientCompleted"
        data-testid="btn-edit-patient-id"
      >
        Change
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="edit-patient-id-card-title"
      >
        Edit patient ID {{ patient.patientNumber }}
      </BoxedToolbar>
      <BoxedCard>
        <SettingsHeader
          title="Patient ID"
          :showIcon="true"
          :patientNo="patient.patientNumber"
        >
          <template #icon>
            <ImgEditPatientInformation />
          </template>
          <template #text>
            <span class="mb-5 d-block">
              <strong
                >Make sure the patient's ID is aligned with IWRS.<br />Be aware
                that these changes will affect the patient’s app.</strong
              >
            </span>
          </template>

          <SettingsItem title="Current patient ID">
            {{ patient.patientNumber }}
          </SettingsItem>
        </SettingsHeader>

        <v-form v-model="formValid" ref="editForm">
          <v-row class="no-gutters">
            <v-col class="offset-4 col-5">
              <FormLabel> 1. Enter correct patient ID </FormLabel>

              <v-text-field
                v-model="newPatientId"
                placeholder="Patient ID"
                outlined
                :rules="patientIdRules"
                :error-messages="patientNumberErrors"
                inputmode="numeric"
                data-testid="input-new-patient-id"
                class="rounded-lg"
                @change="addAuditEntry('PATIENT_ID', $event)"
                @input="checkForDuplicatedPatientNumber"
                @keypress="allowNumbersOnly($event)"
              />

              <FormLabel class="mt-3">
                2. Submit a reason for this change
              </FormLabel>

              <v-radio-group
                v-model="versionReason"
                :rules="versionReasonRules"
                data-testid="select-app-time-format"
                class="boxed-radio-group"
                hide-details
                @change="addAuditEntry('PATIENT_ID:REASON_FOR_CHANGE', $event)"
                ><span
                  v-for="(option, index) in versionReasonOptions"
                  :key="index"
                  class="radio-option"
                  :class="{
                    selected: versionReason === option.value,
                  }"
                >
                  <v-radio
                    :label="option.text"
                    :value="option.value"
                    :data-testid="`version-reason-option-${option.value}`"
                  />
                </span>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-slide-y-reverse-transition :hide-on-leave="true">
            <v-row v-if="versionReason === 'Other'" no-gutters>
              <v-col class="offset-4 col-7">
                <v-textarea
                  counter
                  outlined
                  class="mt-2 reason-text rounded-lg spacing-2"
                  rows="4"
                  :auto-grow="true"
                  v-model="versionReasonProvided"
                  :rules="reasonProvidedRules"
                  :maxlength="reasonProvidedMaxLength"
                  background-color="white"
                  placeholder="Please provide a reason"
                  @change="
                    addAuditEntry('PATIENT_ID:REASON_FOR_CHANGE', $event)
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-slide-y-reverse-transition>

          <v-row class="no-gutters mt-8 pr-4">
            <v-col class="text-right">
              <v-btn @click="closeDialog" text class="btn-cancel-reset-pin mr-4"
                ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
                settings</v-btn
              >
              <v-btn
                @click="showConfirmDialog = true"
                :disabled="!formValid"
                :loading="isLoading"
                color="secondary"
                rounded
                class="elevation-1"
                data-testid="btn-save-patient-id"
                >Save this change</v-btn
              >
            </v-col>
          </v-row>
          <v-row v-if="apiError">
            <v-col class="offset-4 col-8">
              <v-alert type="error" dense text class="mb-0">{{
                apiError
              }}</v-alert>
            </v-col>
          </v-row>
        </v-form>
      </BoxedCard>
      <BaseConfirmDialog
        :isLoading="isLoading"
        v-model="showConfirmDialog"
        @confirm="executeRequest"
      >
        <p>
          <span class="d-block">Patient ID</span>
          Changed from <strong>{{ patient.patientNumber }}</strong> to
          <strong>{{ newPatientId }}</strong>
        </p>
      </BaseConfirmDialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import BaseConfirmDialog from '../_BaseConfirmDialog.vue'
import SettingsHeader from '../_SettingsHeader.vue'
import SettingsItem from '@/components/patient/settings/_SettingsItem.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'

import loadingState from '@/constants/loadingState'
import versionReasonOptions from '@/constants/versionReasonOptions'
import isNumeric from '@/utils/isNumeric'
import AuditLog from '@/utils/audit/AuditLog'
import service from '@/services/patient-service'
import siteService from '@/services/site-patient-service'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import BoxedCard from '@/components/patient/settings/BoxedCard'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'
import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'

const initialState = () => ({
  newPatientId: null,
  versionReason: null,
  versionReasonProvided: null,
  changePending: false,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
  apiPatientNumberError: null,
  updatePatientError: null,
  apiError: null,
})

export default Vue.extend({
  name: 'EditPatientId',
  mixins: [
    allowNumbersOnlyMixin,
    reasonOtherValidationMixin,
    patientSettingsMixin,
  ],
  components: {
    BoxedCard,
    BoxedToolbar,
    BaseConfirmDialog,
    ImgEditPatientInformation,
    FormLabel,
    SettingsItem,
    SettingsHeader,
  },
  props: {
    patient: { type: Object, required: true },
    isUserAllowedToManagePatient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      versionReasonOptions,
      patientIdRules: [v => !!v || 'Patient ID is required.'],
      versionReasonRules: [v => !!v || 'A reason for this change is required.'],
    }
  },
  computed: {
    patientNumberErrors() {
      let v = this.newPatientId
      if (!v) return []

      if (v.length > 2 && !v.startsWith(this.$route.params.siteNo))
        return [`The patient ID must start with ${this.$route.params.siteNo}`]

      if (!isNumeric(v) || v.length !== 6)
        return ['The patient ID must be 6 digits']

      if (v && this.patient.patientNumber === v) {
        return [
          `The new patient ID must be different from the current patient ID`,
        ]
      }

      if (v && this.apiPatientNumberError === v) {
        return [
          `A patient with ID ${this.apiPatientNumberError} already exists`,
        ]
      } else if (
        v &&
        this.updatePatientError &&
        this.updatePatientError === v
      ) {
        return [`A patient with ID ${this.updatePatientError} already exists`]
      }

      return []
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    checkForDuplicatedPatientNumber() {
      const errors = this.patientNumberErrors
      if (errors?.length > 0) return
      if (!this.newPatientId) return

      this.loadingState = loadingState.LOADING

      let requestedPatientNumber = this.newPatientId

      siteService
        .checkPatientNo(this.$route.params.siteNo, requestedPatientNumber)
        .then(response => {
          if (requestedPatientNumber !== this.newPatientId) {
            // A newer request is happening, abandon this response
            return
          }

          if (!response.patientNumberAvailable) {
            this.apiPatientNumberError = this.newPatientId
          }
          this.loadingState = loadingState.LOAD_SUCCEEDED
        })
        .catch(error => {
          if (requestedPatientNumber !== this.newPatientId) {
            // A newer request is happening, abandon this error
            return
          }

          this.$log.error(error)
          this.loadingState = loadingState.LOAD_ERRORED
        })
    },
    executeRequest() {
      this.apiError = null
      this.state = loadingState.LOADING

      const reason =
        this.versionReason === 'Other'
          ? this.versionReasonProvided
          : this.versionReason

      service
        .update(
          this.patient.patientNumber,
          reason,
          this.auditLog.getAuditEntries(),
          {
            newPatientNumber: this.newPatientId,
          }
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated', this.newPatientId)
          this.closeDialog()
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.apiError = error
          this.showConfirmDialog = false

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>
