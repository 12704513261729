<template>
  <div data-testid="patient-settings">
    <div v-if="isLoading" class="py-10">
      <v-progress-linear active indeterminate />
    </div>
    <v-row>
      <v-col sm="12">
        <TrialStatusCard
          v-if="hasPatientLoaded"
          :patient="patient"
          @reloadPatient="reloadPatient($event)"
        />
      </v-col>

      <v-col sm="12">
        <MasterDataCard
          v-if="hasPatientLoaded"
          :patient="patient"
          @reloadPatient="reloadPatient($event)"
        />
      </v-col>
      <v-col sm="12"
        ><PatientInformationCard
          v-if="hasPatientLoaded"
          :patient="patient"
          @reloadPatient="loadPatient($event)"
        />
      </v-col>
    </v-row>

    <EndTrialProgressDialog
      v-model="showPendingDialog"
      :patientNo="patientNo"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import loadingState from '@/constants/loadingState'
import service from '@/services/patient-service'
import TrialStatusCard from '@/components/patient/settings/trialStatus/TrialStatusCard'
import MasterDataCard from '@/components/patient/settings/_MasterDataCard'
import EndTrialProgressDialog from './trialStatus/_EndTrialProgressDialog.vue'
import PatientInformationCard from '@/components/patient/settings/_PatientInformationCard'
import { selectedPatientMapActions } from '@/store/modules/selectedPatientModule'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'

export default Vue.extend({
  name: 'PatientSettings',
  components: {
    PatientInformationCard,
    MasterDataCard,
    TrialStatusCard,
    EndTrialProgressDialog,
  },
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      patient: {},
      state: loadingState.INITIAL,
      showPendingDialog: false,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    hasPatientLoaded() {
      return this.state === loadingState.LOAD_SUCCEEDED
    },
  },
  methods: {
    ...selectedPatientMapActions(),
    reloadPatient(patientNumber) {
      this.loadPatientConfigurationRequested(patientNumber)

      this.loadPatient(patientNumber)
    },
    loadPatient(patientNumber) {
      this.state = loadingState.LOADING

      service
        .getPatient(patientNumber)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.patient = response.data
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          Vue.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
  created() {
    this.loadPatient(this.patientNo)
  },
  watch: {
    patient(newPatient, previousPatient) {
      if (
        previousPatient.currentState &&
        previousPatient.currentState !==
          patientConfigurationPatientState.COMPLETED &&
        newPatient.currentState === patientConfigurationPatientState.COMPLETED
      ) {
        this.showPendingDialog = true
      }
    },
  },
})
</script>

<style lang="scss" scoped></style>
