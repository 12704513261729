<template>
  <BoxedCard>
    <v-form v-model="formValid" ref="form">
      <v-card-title class="font-weight-bold mt-2 pb-3">
        New dose prescription
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col sm="2" class="pt-0">
            <div class="patient-summary-label">Patient ID</div>
            <strong data-testid="summary-patient-id">{{ patientNo }}</strong>
          </v-col>
          <v-col class="pt-0">
            <div class="patient-summary-label">Treatment arm</div>
            <strong data-testid="summary-treatment-arm">{{
              treatmentArmDisplayName
            }}</strong>
          </v-col>
          <v-col class="pt-0" v-if="showRegimen">
            <div class="patient-summary-label">Bolus regimen</div>
            <strong data-testid="summary-bolus-regimen">
              {{ dosingRegimenDisplayName }}
            </strong>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>

        <InitialDoses
          :isDoseRulesLoading="isDoseRulesLoading"
          :site-no="siteNo"
          :context="prescriptionContext.CHANGE_TREATMENT_SETTINGS"
        />

        <v-row class="no-gutters pb-6">
          <v-col class="text-right">
            <v-btn @click="onBack" rounded class="mr-4">Back</v-btn>
            <v-btn
              @click="onSaveChange"
              :disabled="!formValid"
              color="secondary"
              rounded
              class="elevation-1"
              data-testid="btn-save-initial-prescription-changes"
              >Save this change</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </BoxedCard>
</template>

<script>
import BoxedCard from '@/components/patient/settings/BoxedCard'
import InitialDoses from '@/components/patient/add-patient/initial-dose/InitialDoses'
import prescriptionContext from '@/constants/prescriptionContext'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'

export default {
  name: 'InitialPrescriptionForm',
  components: {
    BoxedCard,
    InitialDoses,
  },
  props: {
    isDoseRulesLoading: { type: Boolean, required: true },
    patientNo: { type: String, required: true },
    siteNo: { type: String, required: true },
    newTreatmentArm: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
    },
    newDosingRegimen: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
    },
  },
  data() {
    return {
      formValid: false,
      prescriptionContext,
    }
  },
  computed: {
    ...trialSettingsMapGetters(),
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.newTreatmentArm)
    },
    showRegimen() {
      return this.trialDosingRegimens.length > 1
    },
    dosingRegimenDisplayName() {
      if (this.newDosingRegimen)
        return this.$t(`dosing-regimen.${this.newDosingRegimen}`)

      return ''
    },
  },
  methods: {
    reset() {
      this.$refs.form.resetValidation()
    },
    onSaveChange() {
      this.$emit('save-changes', true)
    },
    onBack() {
      this.$emit('back', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-summary-label {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
</style>
