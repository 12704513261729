
import Vue from 'vue'

import BaseConfirmDialog from '../_BaseConfirmDialog.vue'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'
import SettingsItem from '@/components/patient/settings/_SettingsItem.vue'
import SettingsHeader from '@/components/patient/settings/_SettingsHeader.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'

import loadingState from '@/constants/loadingState'
import dosingDayOptions from '@/constants/dosingDayOptions'

import AuditLog from '@/utils/audit/AuditLog'

import service from '@/services/patient-service'
import BoxedToolbar from '@/components/layout/BoxedToolbar.vue'
import BoxedCard from '@/components/patient/settings/BoxedCard.vue'

const initialState = () => ({
  newDosingDay: null,
  changePending: false,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
  apiError: null,
})

export default Vue.extend({
  name: 'EditDosingDay',
  mixins: [patientSettingsMixin],
  components: {
    BoxedCard,
    BoxedToolbar,
    BaseConfirmDialog,
    FormLabel,
    SettingsItem,
    SettingsHeader,
    ImgEditPatientInformation,
  },
  props: {
    patient: { type: Object, required: true },
    isUserAllowedToManagePatient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      dosingDayRules: [v => !!v || 'Please select a dosing day'],
      dosingDayOptions,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    dosingDayErrors() {
      let value = this.newDosingDay
      if (!value) return []

      if (value === this.patient.dosingDay)
        return ['New dosing day must be different from current dosing day']

      return []
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING

      service
        .update(
          this.patient.patientNumber,
          'Dosing day changed by HCP',
          this.auditLog.getAuditEntries(),
          {
            dosingDay: this.newDosingDay,
          }
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated')
          this.closeDialog()
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED

          this.apiError = error
          this.showConfirmDialog = false

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
