<template>
  <svg
    width="104"
    height="104"
    viewBox="0 0 104 104"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="104" height="104" rx="52" fill="#FBFAF9" />
    <circle cx="49" cy="43" r="9" stroke="#939AA7" stroke-width="2" />
    <path
      d="M34.1715 65.7948C34.1715 59.4245 38.1388 53.1461 43.6429 50.8857M54.4144 51.2572C56.6044 52.1565 59.012 54.0136 60.5458 55.7809"
      stroke="#939AA7"
      stroke-width="2"
    />
    <path
      d="M63.9669 51.7986L67.2223 55.0861L53.9765 68.4627L50.3192 68.8686L50.7211 65.1751L63.9669 51.7986Z"
      stroke="#939AA7"
      stroke-width="2"
    />
    <path
      d="M67.6626 48.2899C68.0512 47.8975 68.6812 47.8975 69.0698 48.2899L70.918 50.1563C71.3066 50.5488 71.3066 51.185 70.918 51.5775L69.4069 53.1035L66.1515 49.8159L67.6626 48.2899Z"
      stroke="#939AA7"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgEditPatientInformation',
}
</script>
