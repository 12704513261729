<template functional>
  <svg
    width="120"
    height="120"
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60 120C93.1371 120 120 93.1371 120 60C120 26.8629 93.1371 0 60 0C26.8629 0 0 26.8629 0 60C0 93.1371 26.8629 120 60 120Z"
      fill="#F5F6F9"
    />
    <path
      d="M62.6764 41.8156C63.477 39.3659 65.8191 37.9379 68.705 38.8819C71.322 39.7423 72.2541 42.2696 71.4534 44.7193C70.6528 47.169 67.4443 50.1326 65.2515 49.4156C62.7242 48.5851 61.8757 44.2593 62.6764 41.8156Z"
      fill="#F5F6F9"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M54.616 52.0266C53.648 49.9772 51.4493 48.9854 49.0355 50.1266C46.8427 51.1603 46.3049 53.4725 47.2729 55.5219C48.2408 57.5713 51.3656 59.8119 53.1999 58.9455C55.3091 57.9477 55.5839 54.076 54.616 52.0266Z"
      fill="#F5F6F9"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M44.7877 79.8934L44.7817 69.0311C44.7817 64.9025 48.1277 61.5506 52.2623 61.5506C55.9308 61.5506 59.4082 64.7651 60.1431 68.3739"
      fill="#F5F6F9"
    />
    <path
      d="M44.7877 79.8934L44.7817 69.0311C44.7817 64.9025 48.1277 61.5506 52.2623 61.5506C55.9308 61.5506 59.4082 64.7651 60.1431 68.3739"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M60.2806 75.7229L60.1133 60.2838C60.1133 55.5696 63.9312 51.7517 68.6454 51.7517C73.3595 51.7517 77.1775 55.5696 77.1775 60.2838L76.9684 69.7659"
      fill="#F5F6F9"
    />
    <path
      d="M60.2806 75.7229L60.1133 60.2838C60.1133 55.5696 63.9312 51.7517 68.6454 51.7517C73.3595 51.7517 77.1775 55.5696 77.1775 60.2838L76.9684 69.7659"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M72.2064 82.2475L57.4724 85.3723C55.1183 85.8742 52.8 84.3865 52.2743 82.0324C51.7843 79.8396 53.0211 77.6349 55.1482 76.9119L68.0419 73.805C74.8114 69.5688 82.8476 67.5553 91.069 70.4531C94.7496 72.0842 97.3964 72.7474 100.826 72.7295H118.564"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M109.586 93.8477L102.906 92.8379H96.2497C87.6161 92.8379 79.0242 94.0389 70.7191 96.4049L60.8547 99.2131C55.4175 100.761 49.5861 99.9599 44.7703 97.0084L20.8589 80.2847C18.451 78.8089 18.1822 75.4152 20.3212 73.5809C21.6536 72.4397 23.5596 72.2545 25.0891 73.1209L46.736 83.541"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M50.5228 13.3931C53.5103 13.3931 56.0257 15.4186 56.7248 18.1611C56.7785 18.3642 57.0653 18.3642 57.1191 18.1611C57.8241 15.4246 60.3336 13.3931 63.321 13.3931C66.8581 13.3931 69.7201 16.2252 69.7201 19.7265C69.7201 26.6752 56.9159 35.8706 56.9159 35.8706C56.9159 35.8706 44.1118 26.6812 44.1118 19.7265C44.1238 16.2252 46.9917 13.3931 50.5228 13.3931Z"
      fill="#F5F6F9"
      stroke="#001965"
      stroke-miterlimit="10"
    />
    <path
      d="M56.9287 22.2777V28.2347"
      stroke="#001965"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
    <path
      d="M53.9473 25.2532H59.9042"
      stroke="#001965"
      stroke-miterlimit="10"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'ImgCare',
}
</script>
