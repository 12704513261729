
import Vue from 'vue'

import SettingsHeader from './_SettingsHeader.vue'
import SettingsItem from './_SettingsItem.vue'
import PatientHistoryDialog from './PatientHistoryDialog.vue'
import EditPatientId from './patientId/EditPatientId.vue'
import EditTreatmentArm from './treatment/EditTreatmentArm.vue'
import EditDosingDay from './dosingDay/EditDosingDay.vue'
import EditDosingRegimen from './regimen/EditDosingRegimen.vue'

import { userMapGetters } from '@/store/modules/userModule'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import trialSettingsGettersMixin from '@/components/mixins/store/trialSettingsGettersMixin'

export default Vue.extend({
  name: 'MasterDataCard',
  mixins: [permissionsGettersMixin, trialSettingsGettersMixin],
  props: {
    patient: { type: Object, required: true },
  },
  components: {
    PatientHistoryDialog,
    SettingsItem,
    SettingsHeader,
    EditPatientId,
    EditTreatmentArm,
    EditDosingDay,
    EditDosingRegimen,
  },
  computed: {
    ...userMapGetters(),
    changeTreatmentArmEnabled() {
      return this.trialFeatureFlags(this.patient.patientNumber)
        .changeTreatmentArmEnabled
    },
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.patient.treatmentArmId)
    },
  },
  methods: {
    reloadPatient() {
      this.$emit('reloadPatient', this.patient.patientNumber)
    },
    redirectPatient(event) {
      this.$router.push({
        name: 'settingsPatient',
        params: { patientNo: event },
      })
      this.$emit('reloadPatient', event)
    },
  },
})
