<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    v-model="showDialog"
    @confirm="deactivateApp"
    activatorText="End participation"
  >
    <div data-testid="end-trial-forceful-dialog-content">
      <strong class="d-block">Trial status</strong>
      Ending patient participation without final data collection
      <WarningBox expand class="mt-2 mb-1">
        Please confirm that you have tried to contact the patient as described
        in the trial protocol.
      </WarningBox>
    </div>
  </BaseConfirmDialog>
</template>

<script>
import Vue from 'vue'

import BaseConfirmDialog from '../_BaseConfirmDialog'
import WarningBox from '@/components/WarningBox'

import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import loadingState from '@/constants/loadingState'
import appState from '@/constants/appState'
import service from '@/services/app-service'
import { notificationMapActions } from '@/store/modules/notificationModule'
import NotificationType from '@/store/modules/notification/NotificationType'

export default {
  name: 'EndTrialForcefullyDialog',
  components: { BaseConfirmDialog, WarningBox },
  props: { patientNo: { type: String, required: true } },
  data() {
    return {
      showDialog: false,
      state: loadingState.INITIAL,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    ...notificationMapActions(),
    deactivateApp() {
      this.state = loadingState.LOADING

      service
        .deleteApp({
          patientNumber: this.patientNo,
          reason: 'Trial Completion',
          currentClientTime: dateTimeWithTimeZone(),
          auditEntries: [],
        })
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED

          if (response.state !== appState.ACTIVATE_BLOCKED) {
            this.spawnNotification({
              type: NotificationType.Error,
              title: 'Deactivation',
              message: `The service didn't deactivate the device`,
              timeToLive: 10000,
            })
          }
          this.$emit('data-updated')
          this.showDialog = false
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.showDialog = false

          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
}
</script>
