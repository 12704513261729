<template>
  <v-dialog v-model="showDialog" :max-width="800">
    <template #activator="{ on }">
      <v-btn
        fab
        x-small
        :color="getAuditDialogBtnColor(versionCount)"
        class="lighten-5 elevation-1"
        v-on="on"
        :data-testid="`btn-${context}-history-dialog`"
      >
        <v-icon>mdi-history</v-icon>
      </v-btn>
    </template>

    <DialogCard
      @closeDialog="showDialog = false"
      :data-testid="`${context}-history-dialog`"
    >
      <template #title> Audit trail for patient </template>

      <v-data-table
        :disable-sort="true"
        :fixed-header="true"
        :headers="getTableHeaders()"
        :items="tableData"
        item-key="rowVersionId"
        :loading="isLoading"
        dense
      >
        <template #no-data>
          <UnableToLoadData v-if="state === loadingState.LOAD_ERRORED" />
        </template>

        <template #loading>
          <v-skeleton-loader
            v-for="i in skeletonCount"
            :key="`skeleton_${i}`"
            height="38"
            type="table-row"
          />
        </template>

        <template #item="{ item }">
          <tr>
            <td v-if="context === 'trial-status'">
              {{ $t('patient-trial-status.' + item.patientState) }}
            </td>

            <td v-if="context === 'master-data'">
              {{ item.patientNumber }}
            </td>
            <td v-if="context === 'master-data' && showTreatmentArm">
              {{ treatmentArmDisplayName(item.treatmentArmId) }}
            </td>
            <td v-if="context === 'master-data' && showDosingRegimen">
              {{ $t(`dosing-regimen.${item.dosingRegimen}`) }}
            </td>
            <td v-if="context === 'master-data'">
              {{ item.dosingDay }}
            </td>

            <td v-if="context === 'patient-information'">
              {{ mapPatientSex(item.sex) }}
            </td>
            <td v-if="context === 'patient-information'">
              {{ item.birthYear }}
            </td>
            <td>
              <small data-testid="item-modified-date-time">
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="dd-MMM-yyyy"
                  data-testid="item-modified-date"
                  class="d-block"
                />
                <DateFormat
                  :value="item.modifiedAt.time"
                  format="HH:mm:ss"
                  data-testid="item-modified-time"
                />
              </small>
            </td>
            <td>
              <small>
                <span class="d-block">
                  <VersionBy :versionBy="item.versionBy" />
                </span>
                <span>{{ item.versionReason }}</span>
              </small>
            </td>
          </tr>
        </template>
      </v-data-table>
    </DialogCard>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import DialogCard from '@/components/DialogCard.vue'
import DateFormat from '@/components/DateFormat.vue'
import VersionBy from '@/components/VersionBy.vue'

import auditDialogMixin from '@/components/mixins/auditDialogMixin'
import loadingState from '@/constants/loadingState'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import hourFormatter from '@/utils/date/hourFormatter'
import service from '@/services/patient-service'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'

export default Vue.extend({
  name: 'PatientHistoryDialog',
  components: {
    DateFormat,
    DialogCard,
    VersionBy,
    UnableToLoadData: () => import('@/components/UnableToLoadData.vue'),
  },
  mixins: [auditDialogMixin],
  props: {
    patientNo: { type: String, required: true },
    versionCount: { type: Number, required: true },
    context: { type: String, required: true },
  },
  data() {
    return {
      state: loadingState.INITIAL,
      loadingState,
      data: null,
      showDialog: false,
    }
  },
  watch: {
    showDialog(newShowDialog) {
      if (newShowDialog) {
        this.loadData()
      }
    },
  },
  computed: {
    ...selectedPatientMapGetters(),
    ...trialSettingsMapGetters(),
    tableData() {
      return this.data?.items || []
    },
    clockNotation() {
      return this.selectedPatientClockNotation(this.patientNo)
    },
    hourFormatString() {
      return hourFormatter(this.clockNotation)
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
    skeletonCount() {
      return this.versionCount > 10 ? 10 : this.versionCount
    },
    getScopes() {
      switch (this.context) {
        case 'trial-status':
          return 'patientState'
        case 'master-data':
          return 'patientNumber,treatmentArm,dosingDay,dosingRegimen'
        case 'patient-information':
          return 'birthDate,sex'
        default:
          return ''
      }
    },
    showTreatmentArm() {
      return this.trialTreatmentArms?.length > 1
    },
    showDosingRegimen() {
      return this.trialDosingRegimens?.length > 1
    },
  },
  methods: {
    treatmentArmDisplayName(from) {
      return this.trialTreatmentName(from)
    },
    mapPatientSex(from) {
      switch (from) {
        case 'f':
          return 'Female'
        case 'm':
          return 'Male'
      }
    },
    getTableHeaders() {
      switch (this.context) {
        case 'trial-status':
          return [
            { text: 'Status', value: 'patientState', sortable: false },
            { text: 'Modified', value: 'versionAt' },
            { text: 'By / reason', value: 'byReason' },
          ]
        case 'master-data':
          return this.getMasterDataHeaders()
        case 'patient-information':
          return [
            { text: 'Sex', value: 'sex', sortable: false },
            { text: 'Birth year', value: 'birthYear', sortable: false },
            { text: 'Modified', value: 'versionAt' },
            { text: 'By / reason', value: 'byReason' },
          ]
      }
    },
    getMasterDataHeaders() {
      let list = [
        {
          text: 'Patient ID',
          value: 'patientNumber',
          sortable: false,
        },
        {
          text: 'Treatment arm',
          value: 'treatmentArmId',
          sortable: false,
        },
        { text: 'Bolus regimen', value: 'dosingRegimen', sortable: false },
        { text: 'Dosing day', value: 'dosingDay', sortable: false },
        { text: 'Modified', value: 'versionAt' },
        { text: 'By / reason', value: 'byReason' },
      ]
      if (!this.showTreatmentArm) {
        list = list.filter(item => item.value !== 'treatmentArmId')
      }
      if (!this.showDosingRegimen) {
        list = list.filter(item => item.value !== 'dosingRegimen')
      }
      return list
    },
    loadData() {
      this.state = loadingState.LOADING
      service
        .getHistory(this.patientNo, this.getScopes)
        .then(response => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.data = response
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
    },
  },
})
</script>
