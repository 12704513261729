
import Vue from 'vue'

import BaseConfirmDialog from '../_BaseConfirmDialog.vue'
import SettingsHeader from '../_SettingsHeader.vue'
import SettingsItem from '../_SettingsItem.vue'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'

import loadingState from '@/constants/loadingState'
import versionReasonOptions from '@/constants/versionReasonOptions'
import service from '@/services/patient-service'
import AuditLog from '@/utils/audit/AuditLog'
import BoxedToolbar from '@/components/layout/BoxedToolbar.vue'
import BoxedCard from '@/components/patient/settings/BoxedCard.vue'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'

const initialState = () => ({
  newSex: null,
  versionReason: null,
  versionReasonProvided: null,
  changePending: false,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
})

export default Vue.extend({
  name: 'EditPatientSex',
  mixins: [reasonOtherValidationMixin, patientSettingsMixin],
  components: {
    BoxedCard,
    BoxedToolbar,
    BaseConfirmDialog,
    FormLabel,
    ImgEditPatientInformation,
    SettingsItem,
    SettingsHeader,
  },
  props: {
    patient: { type: Object, required: true },
    isUserAllowedToManagePatient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      versionReasonOptions,
      sexOptions: [
        { value: 'f', text: 'Female' },
        { value: 'm', text: 'Male' },
      ],
      patientSexRules: [v => !!v || 'Patient sex is required.'],
      versionReasonRules: [v => !!v || 'A reason for this change is required.'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    sexErrors() {
      let value = this.newSex
      if (!value) return []

      const currentSex = this.patient.sex.charAt(0).toLocaleLowerCase()
      if (value === currentSex)
        return ['Sex must be different from current sex']

      return []
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()
      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING
      const reason =
        this.versionReason === 'Other'
          ? this.versionReasonProvided
          : this.versionReason

      service
        .update(
          this.patient.patientNumber,
          reason,
          this.auditLog.getAuditEntries(),
          {
            sex: this.newSex,
          }
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.showConfirmDialog = false
          this.closeDialog()
        })
    },
  },
})
