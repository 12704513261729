
import Vue from 'vue'

import SettingsHeader from '../_SettingsHeader.vue'
import SettingsItem from '../_SettingsItem.vue'
import EditTrialStatus from './EditTrialStatus.vue'
import EndTrialForcefullyDialog from './_EndTrialForcefullyDialog.vue'
import PatientHistoryDialog from '@/components/patient/settings/PatientHistoryDialog.vue'
import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState'
import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'

export default Vue.extend({
  name: 'TrialStatusCard',
  props: {
    patient: { type: Object, required: true },
  },
  components: {
    PatientHistoryDialog,
    EditTrialStatus,
    EndTrialForcefullyDialog,
    SettingsItem,
    SettingsHeader,
  },
  computed: {
    ...selectedPatientMapGetters(),
    appState() {
      return (
        this.selectedPatientConfiguration(this.patient.patientNumber)
          ?.appState || ''
      )
    },
    patientState() {
      return (
        this.selectedPatientConfiguration(this.patient.patientNumber)
          ?.patientState?.currentState || ''
      )
    },
    isAppStateBlocked() {
      return this.appState === patientConfigurationAppState.BLOCKED
    },
    isPatientCompleted() {
      return this.patientState === patientConfigurationPatientState.COMPLETED
    },
    oneHourPassed() {
      const completeStartedAt = new Date(
        this.selectedPatientConfiguration(
          this.patient.patientNumber
        )?.patientState.completeStartedAt.time
      )

      const oneHourAgo = new Date()
      oneHourAgo.setHours(oneHourAgo.getHours() - 1)

      return completeStartedAt < oneHourAgo
    },
  },
  methods: {
    reloadPatient() {
      this.$emit('reloadPatient', this.patient.patientNumber)
    },
  },
})
