
import Vue from 'vue'

import SettingsHeader from '../_SettingsHeader.vue'
import SettingsItem from '../_SettingsItem.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'

import loadingState from '@/constants/loadingState'
import AuditLog from '@/utils/audit/AuditLog'
import service from '@/services/patient-service'
import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'
import BoxedToolbar from '@/components/layout/BoxedToolbar.vue'
import BoxedCard from '@/components/patient/settings/BoxedCard.vue'

const initialState = () => ({
  newStatus: null,
  changePending: false,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
})

export default Vue.extend({
  name: 'EditTrialStatus',
  mixins: [permissionsGettersMixin],
  components: {
    BoxedCard,
    BoxedToolbar,
    ImgEditPatientInformation,
    FormLabel,
    SettingsItem,
    SettingsHeader,
    ActiveDialog: () => import('./_ActiveDialog.vue'),
    FollowUpDialog: () => import('./_FollowUpDialog.vue'),
    EndTrialDialog: () => import('./_EndTrialDialog.vue'),
  },
  props: {
    patient: { type: Object, required: true },
    disabled: { type: Boolean, required: true },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      statusOptions: [
        { value: 'activated', text: 'Active' },
        { value: 'in_follow_up', text: 'In follow up' },
        { value: 'completed', text: 'End of participation' },
      ],
      statusOptionsRules: [v => !!v || 'Patient status is required.'],
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    statusErrors() {
      let value = this.newStatus
      if (!value) return []

      if (value === this.patient.currentState)
        return ['New status must be different from current status']

      return []
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING

      service
        .updateState(
          this.patient.patientNumber,
          this.auditLog.getAuditEntries(),
          this.newStatus
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.showConfirmDialog = false
          this.closeDialog()
        })
    },
  },
})
