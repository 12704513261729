<template>
  <v-dialog v-model="show" max-width="424" persistent>
    <v-card class="pt-2">
      <v-btn
        icon
        @click="show = false"
        class="close-btn-absolut"
        data-testid="end-trial-progress-dialog-cancel"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>

      <v-card-text class="card-text">
        <div
          class="pt-14 d-flex flex-column align-center text-center"
          v-if="isAppStateBlocked"
          data-testid="end-trial-progress-dialog-finished"
        >
          <ImgReturnDevices />

          <h4 class="py-2">End of participation successful</h4>
          <span>
            All data for patient {{ patientNo }} has been transferred to the
            dashboard and the app has been successfully deactivated.
          </span>
        </div>

        <div
          class="pt-14 d-flex flex-column align-center text-center"
          v-else
          data-testid="end-trial-progress-dialog-waiting"
        >
          <ImgCare />
          <v-progress-circular
            :size="50"
            color="grey"
            indeterminate
            class="my-4"
          />
          <h4 class="pb-2">
            Proceed to end of participation<br />
            on the patient’s app
          </h4>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ImgCare from '@/components/images/ImgCare.vue'
import ImgReturnDevices from '@/components/images/ImgReturnDevices.vue'

import { selectedPatientMapGetters } from '@/store/modules/selectedPatientModule'
import patientConfigurationAppState from '@/constants/patientConfigurationAppState'

export default {
  name: 'EndTrialProgressDialog',
  components: { ImgCare, ImgReturnDevices },
  props: {
    value: { type: Boolean, required: true },
    patientNo: { type: String, required: true },
  },
  computed: {
    ...selectedPatientMapGetters(),
    appState() {
      return this.selectedPatientConfiguration(this.patientNo)?.appState || ''
    },
    isAppStateBlocked() {
      return this.appState === patientConfigurationAppState.BLOCKED
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.close-btn-absolut {
  position: absolute;
  top: 12px;
  right: 12px;
}
.card-text {
  color: $nn-black;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
</style>
