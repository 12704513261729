<template>
  <PatientSettings :patient-no="patientNo" />
</template>

<script>
import Vue from 'vue'
import PatientSettings from '@/components/patient/settings/PatientSettings.vue'

export default Vue.extend({
  components: { PatientSettings },
  props: { patientNo: { type: String, required: true } },
})
</script>
