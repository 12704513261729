<template>
  <v-dialog
    v-model="show"
    :max-width="width"
    persistent
    data-testid="base-confirm-dialog"
  >
    <template #activator="{ on }" v-if="activatorText">
      <v-btn
        rounded
        color="secondary"
        @click="show = true"
        class="elevation-1"
        v-on="on"
        data-testid="base-confirm-dialog-activator"
      >
        {{ activatorText }}
      </v-btn>
    </template>

    <DialogCard @closeDialog="show = false">
      <template #title> Confirm the following changes </template>
      <template #actions>
        <v-btn
          rounded
          depressed
          @click.stop="show = false"
          class="mr-4 elevation-1"
          data-testid="base-confirm-dialog-cancel"
          >Cancel</v-btn
        >
        <v-btn
          color="secondary"
          rounded
          depressed
          @click="$emit('confirm')"
          :loading="isLoading"
          class="elevation-1"
          :disabled="disabled"
          data-testid="base-confirm-dialog-confirm"
          >Confirm</v-btn
        >
      </template>
      <slot />
    </DialogCard>
  </v-dialog>
</template>

<script>
import DialogCard from '@/components/DialogCard'

export default {
  name: 'BaseConfirmDialog',
  components: { DialogCard },
  props: {
    value: { type: Boolean, required: true },
    isLoading: { type: Boolean, required: true },
    disabled: { type: Boolean, required: false, default: false },
    activatorText: { type: [String, null], required: false },
    width: { type: Number, required: false, default: 500 },
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
