<template>
  <v-dialog v-model="showDialog" fullscreen data-testid="edit-patient-birth">
    <template #activator="{ on }">
      <v-btn
        v-if="isUserAllowedToManagePatient"
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
        :disabled="isPatientCompleted"
        data-testid="btn-change-patient-birth"
      >
        Change
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="edit-patient-birth-card-title"
      >
        Edit patient birth year {{ patient.patientNumber }}
      </BoxedToolbar>

      <BoxedCard>
        <SettingsHeader
          title="Patient's birth year"
          :showIcon="true"
          :patientNo="patient.patientNumber"
        >
          <template #icon>
            <ImgEditPatientInformation />
          </template>
          <template #text>
            <strong class="mb-5 d-block"
              >Make sure the patient's birth year is aligned with IWRS.</strong
            >
          </template>
          <SettingsItem title="Current birth year" :hide-lock="true">
            {{ patient.birthYear }}
          </SettingsItem>
        </SettingsHeader>
        <v-form v-model="formValid" ref="editForm">
          <v-row class="no-gutters">
            <v-col class="offset-4 col-5">
              <FormLabel> 1. Enter a different birth year </FormLabel>

              <v-text-field
                v-model="newBirthYear"
                placeholder="Birth year"
                outlined
                :rules="birthYearRules"
                :error-messages="birthYearErrors"
                inputmode="numeric"
                data-testid="input-patient-birth-year"
                class="rounded-lg"
                @change="addAuditEntry('PATIENT_BIRTH_YEAR', $event)"
                @keypress="allowNumbersOnly($event)"
              />

              <FormLabel class="mt-3">
                2. Submit a reason for this change
              </FormLabel>

              <v-radio-group
                v-model="versionReason"
                :rules="versionReasonRules"
                data-testid="select-version-reason"
                class="boxed-radio-group"
                hide-details
                @change="
                  addAuditEntry('PATIENT_BIRTH_YEAR:REASON_FOR_CHANGE', $event)
                "
                ><span
                  v-for="(option, index) in versionReasonOptions"
                  :key="index"
                  class="radio-option"
                  :class="{
                    selected: versionReason === option.value,
                  }"
                >
                  <v-radio
                    :label="option.text"
                    :value="option.value"
                    :data-testid="`version-reason-option-${option.value}`"
                  />
                </span>
              </v-radio-group>
            </v-col>
          </v-row>

          <v-slide-y-reverse-transition :hide-on-leave="true">
            <v-row v-if="versionReason === 'Other'" no-gutters>
              <v-col class="offset-4 col-7">
                <v-textarea
                  counter
                  outlined
                  class="mt-2 reason-text rounded-lg spacing-2"
                  rows="4"
                  :auto-grow="true"
                  v-model="versionReasonProvided"
                  :rules="reasonProvidedRules"
                  :maxlength="reasonProvidedMaxLength"
                  background-color="white"
                  placeholder="Please provide a reason"
                  @change="
                    addAuditEntry(
                      'PATIENT_BIRTH_YEAR:REASON_FOR_CHANGE_TEXT',
                      $event
                    )
                  "
                ></v-textarea>
              </v-col>
            </v-row>
          </v-slide-y-reverse-transition>

          <v-row class="no-gutters mt-8 pr-4">
            <v-col class="text-right">
              <v-btn
                @click="closeDialog"
                text
                class="btn-cancel-reset-pin mr-4"
                data-testid="btn-cancel"
                ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
                settings</v-btn
              >
              <v-btn
                @click="showConfirmDialog = true"
                :disabled="!formValid"
                :loading="isLoading"
                color="secondary"
                rounded
                class="elevation-1"
                data-testid="btn-save-changes"
                >Save this change</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </BoxedCard>
      <BaseConfirmDialog
        :isLoading="isLoading"
        v-model="showConfirmDialog"
        @confirm="executeRequest"
      >
        <p data-testid="selected-birth-year">
          <span class="d-block">Patient's birth year</span>
          Changed from <strong>{{ patient.birthYear }}</strong> to
          <strong>{{ newBirthYear }}</strong>
        </p>
      </BaseConfirmDialog>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

import BaseConfirmDialog from '../_BaseConfirmDialog.vue'
import SettingsHeader from '../_SettingsHeader.vue'
import SettingsItem from '../_SettingsItem.vue'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'

import loadingState from '@/constants/loadingState'
import service from '@/services/patient-service'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'
import versionReasonOptions from '@/constants/versionReasonOptions'
import AuditLog from '@/utils/audit/AuditLog'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import BoxedCard from '@/components/patient/settings/BoxedCard'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'
import allowNumbersOnlyMixin from '@/components/mixins/allowNumbersOnlyMixin'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'

const initialState = () => ({
  newBirthYear: null,
  versionReason: null,
  versionReasonProvided: null,
  changePending: false,
  state: loadingState.INITIAL,
  auditLog: new AuditLog(),
})

export default Vue.extend({
  name: 'EditPatientBirth',
  mixins: [
    allowNumbersOnlyMixin,
    reasonOtherValidationMixin,
    patientSettingsMixin,
  ],
  components: {
    BoxedCard,
    BoxedToolbar,
    BaseConfirmDialog,
    FormLabel,
    ImgEditPatientInformation,
    SettingsItem,
    SettingsHeader,
  },
  props: {
    patient: { type: Object, required: true },
    isUserAllowedToManagePatient: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ...initialState(),
      formValid: false,
      showDialog: false,
      showConfirmDialog: false,
      loadingState,
      versionReasonOptions,
      birthYearRules: [v => !!v || 'Year of birth is required.'],
      versionReasonRules: [v => !!v || 'A reason for this change is required.'],
    }
  },
  computed: {
    birthYearErrors() {
      let value = this.newBirthYear
      if (!value) return []

      const v = Number(value)

      if (!v) return ['Year must be a number']

      if (v < 1000 || v > 9999) return ['Year must be 4 digits']

      if (v < 1900 || v > new Date().getFullYear())
        return [`Year must be between 1900 and ${new Date().getFullYear()}`]

      const current = this.patient.birthYear
      if (v === current)
        return ['Birth year must be different from current birth year']

      return []
    },
    isLoading() {
      return this.state === loadingState.LOADING
    },
  },
  methods: {
    openDialog() {
      if (this.$refs.editForm) this.$refs.editForm.reset()

      Object.assign(this.$data, initialState())
      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
    },
    addAuditEntry(fieldName, value) {
      this.auditLog.log(fieldName, value)
    },
    executeRequest() {
      this.state = loadingState.LOADING
      const reason =
        this.versionReason === 'Other'
          ? this.versionReasonProvided
          : this.versionReason

      service
        .update(
          this.patient.patientNumber,
          reason,
          this.auditLog.getAuditEntries(),
          {
            birthYear: this.newBirthYear,
          }
        )
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated')
        })
        .catch(error => {
          this.state = loadingState.LOAD_ERRORED
          this.$log.error(error)
          Vue.$tracking.componentError(this, error)
        })
        .finally(() => {
          this.showConfirmDialog = false
          this.closeDialog()
        })
    },
  },
})
</script>
