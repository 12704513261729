<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    :value="value"
    @input="$emit('input', $event)"
    @confirm="$emit('confirm')"
    :width="confirmationWidth"
  >
    <p>
      <span class="d-block">Treatment arm</span>
      Changed from <strong>{{ treatmentArmDisplayName }}</strong> to
      <strong>{{ newTreatmentArmDisplayName }}.</strong>
      <span v-if="showRegimen" class="d-block">
        Bolus regimen:
        {{ $t(`dosing-regimen.${patient.dosingRegimen}`) }}
      </span>
    </p>

    <DoseOverview
      :site-no="siteNo"
      :context="prescriptionContext.CHANGE_TREATMENT_SETTINGS"
    />
  </BaseConfirmDialog>
</template>

<script>
import BaseConfirmDialog from '../_BaseConfirmDialog'
import DoseOverview from '@/components/patient/add-patient/initial-dose/DoseOverview'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import prescriptionContext from '@/constants/prescriptionContext'
import dosingRegimen from '@/constants/dosingRegimen'

export default {
  name: 'ConfirmChangeDialog',
  props: {
    value: { type: Boolean, required: true },
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
    siteNo: { type: String, required: true },
    newTreatmentArm: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
    },
  },
  components: { BaseConfirmDialog, DoseOverview },
  data() {
    return {
      prescriptionContext,
    }
  },
  computed: {
    ...trialSettingsMapGetters(),
    treatmentArmDisplayName() {
      return this.trialTreatmentName(this.patient.treatmentArmId)
    },
    newTreatmentArmDisplayName() {
      return this.trialTreatmentName(this.newTreatmentArm)
    },
    showRegimen() {
      return this.trialDosingRegimens.length > 1
    },
    confirmationWidth() {
      return this.patient.dosingRegimen === dosingRegimen.CARB_COUNT
        ? 1080
        : 750
    },
  },
}
</script>

<style lang="scss" scoped>
.date-chip {
  display: block !important;
  width: fit-content;
  margin-bottom: 16px;
}
</style>
