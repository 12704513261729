import patientConfigurationPatientState from '@/constants/patientConfigurationPatientState'

export default {
  computed: {
    isPatientCompleted() {
      return (
        this.patient.currentState === patientConfigurationPatientState.COMPLETED
      )
    },
  },
}
