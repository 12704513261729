<template>
  <v-card class="rounded-lg">
    <SettingsHeader title="Patient details">
      <template #history>
        <PatientHistoryDialog
          :versionCount="1"
          :patientNo="patient.patientNumber"
          context="patient-information"
        />
      </template>
      <SettingsItem title="Patient's sex">
        <template #action>
          <EditPatientSex
            :patient="patient"
            @data-updated="reloadPatient"
            :isUserAllowedToManagePatient="isUserAllowedToManagePatient"
          />
        </template>
        {{ patient.sex }}
      </SettingsItem>

      <SettingsItem title="Patient's birth year">
        <template #action>
          <EditPatientBirth
            :patient="patient"
            @data-updated="reloadPatient"
            :isUserAllowedToManagePatient="isUserAllowedToManagePatient"
          />
        </template>
        {{ patient.birthYear }}
      </SettingsItem>
    </SettingsHeader>
  </v-card>
</template>

<script>
import Vue from 'vue'

import SettingsHeader from './_SettingsHeader.vue'
import SettingsItem from './_SettingsItem.vue'
import EditPatientSex from '@/components/patient/settings/patientSex/EditPatientSex.vue'
import EditPatientBirth from '@/components/patient/settings/patientBirth/EditPatientBirth.vue'
import PatientHistoryDialog from '@/components/patient/settings/PatientHistoryDialog.vue'

import permissionsGettersMixin from '@/components/mixins/store/permissionsGettersMixin'

export default Vue.extend({
  name: 'PatientInformationCard',
  props: { patient: { type: Object, required: true } },
  mixins: [permissionsGettersMixin],
  components: {
    PatientHistoryDialog,
    EditPatientBirth,
    EditPatientSex,
    SettingsItem,
    SettingsHeader,
  },

  methods: {
    reloadPatient() {
      this.$emit('reloadPatient', this.patient.patientNumber)
    },
  },
})
</script>
