<template>
  <BaseConfirmDialog
    :isLoading="isLoading"
    :value="value"
    @input="$emit('input', $event)"
    @confirm="$emit('confirm')"
    :width="confirmationWidth"
  >
    <p>
      <span class="d-block">Bolus regimen</span>
      Changed form
      <strong>{{ $t(`dosing-regimen.${patient.dosingRegimen}`) }}</strong> to
      <strong>{{ dosingRegimenDisplayName }}.</strong>
    </p>

    <DoseOverview
      :site-no="siteNo"
      :context="prescriptionContext.CHANGE_TREATMENT_SETTINGS"
    />
  </BaseConfirmDialog>
</template>

<script>
import BaseConfirmDialog from '../_BaseConfirmDialog'
import DoseOverview from '@/components/patient/add-patient/initial-dose/DoseOverview'
import prescriptionContext from '@/constants/prescriptionContext'
import dosingRegimen from '@/constants/dosingRegimen'

export default {
  name: 'ConfirmChangeDialog',
  props: {
    value: { type: Boolean, required: true },
    patient: { type: Object, required: true },
    isLoading: { type: Boolean, required: true },
    siteNo: { type: String, required: true },
    newDosingRegimen: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
    },
  },
  components: { BaseConfirmDialog, DoseOverview },
  data() {
    return {
      prescriptionContext,
    }
  },
  computed: {
    confirmationWidth() {
      return this.newDosingRegimen === dosingRegimen.CARB_COUNT ? 1080 : 750
    },
    dosingRegimenDisplayName() {
      if (this.newDosingRegimen)
        return this.$t(`dosing-regimen.${this.newDosingRegimen}`)

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
.date-chip {
  display: block !important;
  width: fit-content;
  margin-bottom: 16px;
}
</style>
