<template>
  <BoxedCard>
    <SettingsHeader
      title="Bolus regimen"
      :showIcon="true"
      :patientNo="patient.patientNumber"
    >
      <template #icon>
        <ImgEditPatientInformation />
      </template>
      <template #text>
        <strong class="mb-5 d-block iwrs-text"
          >Make sure the patient's treatment arm is aligned with IWRS.<br />
          Be aware that these changes will affect the patient's app.</strong
        >
      </template>
      <SettingsItem title="Current Bolus regimen">
        {{ $t(`dosing-regimen.${patient.dosingRegimen}`) }}
      </SettingsItem>
    </SettingsHeader>

    <v-form v-model="formValid" ref="form">
      <v-row class="no-gutters">
        <v-col class="offset-4 col-5">
          <FormLabel> 1. Select a bolus regimen </FormLabel>

          <v-radio-group
            :value="newDosingRegimen"
            :rules="dosingRegimenRules"
            :error-messages="dosingRegimenErrors"
            class="boxed-radio-group"
            @change="onChangeDosingRegimen($event)"
          >
            <span
              v-for="(option, index) in dosingRegimenOptions"
              :key="index"
              class="radio-option"
              :class="{
                selected: newDosingRegimen === option.value,
              }"
            >
              <v-radio
                :label="option.text"
                :value="option.value"
                :data-testid="`patient-treatment-option-${option.value}`"
              />
            </span>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-row class="no-gutters">
        <v-col class="offset-4 col-5">
          <FormLabel class="mt-3">
            2. Submit a reason for this change
          </FormLabel>

          <v-radio-group
            :value="versionReason"
            :rules="versionReasonRules"
            data-testid="select-version-reason"
            class="boxed-radio-group"
            hide-details
            @change="onChangeVersionReason($event)"
            ><span
              v-for="(option, index) in versionReasonOptions"
              :key="index"
              class="radio-option"
              :class="{
                selected: versionReason === option.value,
              }"
            >
              <v-radio
                :label="option.text"
                :value="option.value"
                :data-testid="`version-reason-option-${option.value}`"
              />
            </span>
          </v-radio-group>
        </v-col>
      </v-row>

      <v-slide-y-reverse-transition :hide-on-leave="true">
        <v-row v-if="versionReason === 'Other'" no-gutters>
          <v-col class="offset-4 col-7">
            <v-textarea
              counter
              outlined
              class="mt-2 reason-text rounded-lg spacing-2"
              rows="4"
              :auto-grow="true"
              :value="versionReasonProvided"
              :rules="reasonProvidedRules"
              :maxlength="reasonProvidedMaxLength"
              background-color="white"
              placeholder="Please provide a reason"
              @change="onChangeVersionReasonProvided($event)"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-slide-y-reverse-transition>

      <v-row class="no-gutters mt-8 pr-4">
        <v-col class="text-right">
          <v-btn @click="onCancel" text class="btn-cancel-reset-pin mr-4"
            ><v-icon>mdi-chevron-left</v-icon> Cancel & return to
            settings</v-btn
          >
          <v-btn
            @click="onContinue"
            :disabled="!formValid"
            color="secondary"
            rounded
            class="elevation-1"
            >Continue</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </BoxedCard>
</template>

<script>
import BoxedCard from '@/components/patient/settings/BoxedCard'
import SettingsItem from '@/components/patient/settings/_SettingsItem.vue'
import SettingsHeader from '@/components/patient/settings/_SettingsHeader'
import ImgEditPatientInformation from '@/components/images/ImgEditPatientInformation.vue'
import FormLabel from '@/components/patient/add-patient/shared/FormLabel.vue'
import { trialSettingsMapGetters } from '@/store/modules/trialSettingsModule'
import versionReasonOptions from '@/constants/versionReasonOptions'
import treatmentSettingsActionsMixin from '@/components/mixins/store/treatmentSettingsActionsMixin'
import treatmentSettingsGettersMixin from '@/components/mixins/store/treatmentSettingsGettersMixin'
import reasonOtherValidationMixin from '@/components/mixins/reasonOtherValidationMixin'

export default {
  name: 'TreatmentChangeForm',
  mixins: [
    treatmentSettingsActionsMixin,
    treatmentSettingsGettersMixin,
    reasonOtherValidationMixin,
  ],
  components: {
    BoxedCard,
    FormLabel,
    ImgEditPatientInformation,
    SettingsItem,
    SettingsHeader,
  },
  props: {
    patient: { type: Object, required: true },
  },
  data() {
    return {
      formValid: false,
      dosingRegimenRules: [v => !!v || 'Bolus regimen is required'],
      versionReasonRules: [v => !!v || 'A reason for this change is required.'],
      versionReasonOptions,
    }
  },
  computed: {
    ...trialSettingsMapGetters(),
    newDosingRegimen() {
      return this.treatmentSettingsInfo.dosingRegimen
    },
    dosingRegimenOptions() {
      return this.trialDosingRegimens.map(regimen => ({
        value: regimen,
        text: this.$t(`dosing-regimen.${regimen}`),
      }))
    },
    dosingRegimenErrors() {
      let v = this.newDosingRegimen
      if (!v) return []

      if (v === this.patient.dosingRegimen)
        return ['The bolus regimen must be different from the current']

      return []
    },
    versionReason() {
      return this.treatmentSettingsInfo.versionReason
    },
    versionReasonProvided() {
      return this.treatmentSettingsInfo.versionReasonProvided
    },
  },
  methods: {
    reset() {
      this.$refs.form.resetValidation()
    },
    onChangeDosingRegimen(value) {
      this.treatmentSettingsUpdateInfo({ dosingRegimen: value })
      this.treatmentSettingsUpdateInfoAuditLog({
        name: 'PATIENT_DOSING_REGIMEN',
        value,
      })
    },
    onChangeVersionReason(value) {
      this.treatmentSettingsUpdateInfo({ versionReason: value })
      this.treatmentSettingsUpdateInfoAuditLog({
        name: 'PATIENT_DOSING_REGIMEN:REASON_FOR_CHANGE',
        value,
      })
    },
    onChangeVersionReasonProvided(value) {
      this.treatmentSettingsUpdateInfo({ versionReasonProvided: value })
      this.treatmentSettingsUpdateInfoAuditLog({
        name: 'PATIENT_DOSING_REGIMEN:REASON_FOR_CHANGE_TEXT',
        value,
      })
    },
    onContinue() {
      this.$emit('next-step', true)
    },
    onCancel() {
      this.$emit('cancel', true)
    },
  },
}
</script>

<style lang="scss" scoped>
.iwrs-text {
  color: $nn-D_T30;
}
</style>
