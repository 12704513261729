<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    data-testid="edit-dosing-regimen-dialog"
  >
    <template #activator="{ on }">
      <v-btn
        rounded
        color="secondary"
        @click="openDialog"
        class="elevation-1"
        v-on="on"
        :disabled="isPatientCompleted || !isDeviceSupported"
        data-testid="btn-change-dosing-regimen"
      >
        Change
      </v-btn>
    </template>

    <v-card color="#F4F8FE">
      <BoxedToolbar
        @close-dialog="closeDialog"
        data-testid="edit-dosing-regimen-toolbar"
      >
        Edit patient's dosing regimen {{ patient.patientNumber }}
      </BoxedToolbar>

      <v-stepper v-model="overallStep" alt-labels>
        <v-stepper-items>
          <v-stepper-content step="1" class="pt-0 pb-1">
            <DosingRegimenForm
              @cancel="closeDialog"
              @next-step="goToPrescribeDose"
              :patient="patient"
              ref="dosingRegimenForm"
            />
          </v-stepper-content>

          <v-stepper-content step="2" class="pt-0 pb-1">
            <InitialPrescriptionForm
              @back="overallStep = 1"
              @save-changes="openConfirmDialog"
              :isDoseRulesLoading="isDoseRulesLoading"
              :siteNo="siteNo"
              :patientNo="patient.patientNumber"
              :newTreatmentArm="patient.treatmentArmId"
              :newDosingRegimen="newDosingRegimen"
              ref="initialPrescriptionForm"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <ConfirmChangeDialog
        v-model="showConfirmDialog"
        :isLoading="isLoading"
        :patient="patient"
        :siteNo="siteNo"
        :newDosingRegimen="newDosingRegimen"
        @confirm="executeRequest"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import loadingState from '@/constants/loadingState'
import isNumeric from '@/utils/isNumeric'
import dateTimeWithTimeZone from '@/utils/date/dateTimeWithTimeZone'
import service from '@/services/patient-service'
import prescriptionService from '@/services/prescription-service'
import BoxedToolbar from '@/components/layout/BoxedToolbar'
import patientSettingsMixin from '@/components/mixins/patientSettingsMixin'
import DosingRegimenForm from './_DosingRegimenForm.vue'
import InitialPrescriptionForm from '../shared/_InitialPrescriptionForm.vue'
import treatmentSettingsGettersMixin from '@/components/mixins/store/treatmentSettingsGettersMixin'
import treatmentSettingsActionsMixin from '@/components/mixins/store/treatmentSettingsActionsMixin'
import prescriptionType from '@/constants/prescriptionType'
import ConfirmChangeDialog from './_ConfirmChangeDialog'
import deviceInfo from '@/utils/deviceInfo'

export default {
  name: 'EditDosingRegimen',
  mixins: [
    patientSettingsMixin,
    treatmentSettingsGettersMixin,
    treatmentSettingsActionsMixin,
  ],
  components: {
    BoxedToolbar,
    DosingRegimenForm,
    InitialPrescriptionForm,
    ConfirmChangeDialog,
  },
  props: {
    patient: { type: Object, required: true },
  },
  data() {
    return {
      overallStep: 1,
      showDialog: false,
      showConfirmDialog: false,
      loadingState: loadingState.INITIAL,
      doseRulesLoadingState: loadingState.INITIAL,
      isDeviceSupported: false,
    }
  },
  computed: {
    isLoading() {
      return this.state === loadingState.LOADING
    },
    isDoseRulesLoading() {
      return this.doseRulesLoadingState === loadingState.LOADING
    },
    newDosingRegimen() {
      return this.treatmentSettingsInfo.dosingRegimen
    },
    siteNo() {
      return this.$route.params.siteNo
    },
  },
  created() {
    this.isDeviceSupported = deviceInfo.isIpadAir2019()
  },
  methods: {
    openDialog() {
      if (this.$refs.dosingRegimenForm) this.$refs.dosingRegimenForm.reset()
      if (this.$refs.initialPrescriptionForm)
        this.$refs.initialPrescriptionForm.reset()

      this.showDialog = true
    },
    closeDialog() {
      this.showDialog = false
      this.overallStep = 1
      this.treatmentSettingsClearAll()
    },
    goToPrescribeDose() {
      this.loadDoseInitials()
      this.overallStep = 2
    },
    openConfirmDialog() {
      this.showConfirmDialog = true
    },

    loadDoseInitials() {
      const allowCall = !!(
        this.patient.patientNumber &&
        this.patient.sex &&
        this.patient.birthYear &&
        this.newDosingRegimen
      )

      if (!allowCall) {
        return
      }

      const request = {
        siteNumber: this.$route.params.siteNo,
        patientNumber: this.patient.patientNumber,
        treatmentArmId: this.patient.treatmentArmId,
        sex: this.patient.sex,
        birthDay: this.patient.birthDay,
        birthMonth: this.patient.birthMonth,
        birthYear: this.patient.birthYear,
        dosingRegimen: this.newDosingRegimen,
      }

      this.doseRulesLoadingState = loadingState.LOADING
      prescriptionService
        .getInitialization(request)
        .then(result => {
          this.doseRulesLoadingState = loadingState.LOAD_SUCCEEDED
          const bolusPrescription = result.data.items.filter(presc => {
            return (
              presc.type === prescriptionType.BOLUS_CARB_COUNT ||
              presc.type === prescriptionType.BOLUS
            )
          })
          this.treatmentSettingsUpdateDoseRules(bolusPrescription || [])
        })
        .catch(error => {
          this.doseRulesLoadingState = loadingState.LOAD_ERRORED
          this.$log.error(error)
          this.treatmentSettingsUpdateDoseRules([])
        })
    },

    executeRequest() {
      this.state = loadingState.LOADING

      const dateTimeZone = dateTimeWithTimeZone()

      const versionReason =
        this.treatmentSettingsInfo.versionReason === 'Other'
          ? this.treatmentSettingsInfo.versionReasonProvided
          : this.treatmentSettingsInfo.versionReason

      let prescriptions = []

      this.treatmentSettingsDoseRules.forEach(doseRule => {
        let prescription = {
          prescriptionConfigurationId: doseRule.prescriptionConfigurationId,
          type: doseRule.type,
          doses: [],
          bloodGlucoseUnit:
            doseRule.type === prescriptionType.BOLUS_CARB_COUNT
              ? this.treatmentSettingsPrescription(
                  doseRule.prescriptionConfigurationId
                ).bloodGlucoseUnit
              : null,
        }

        doseRule.doseConfiguration.forEach(initialDose => {
          const treatment = this.treatmentSettingsPrescription(
            prescription.prescriptionConfigurationId
          )
          const dose = treatment[initialDose.timepoint]
          if (dose.enabled) {
            if (doseRule.type === prescriptionType.BOLUS_CARB_COUNT) {
              if (
                isNumeric(dose.insulinSensitivityFactor) &&
                isNumeric(dose.insulinToCarbRatio)
              ) {
                prescription.doses.push({
                  timepoint: initialDose.timepoint,
                  insulinSensitivityFactor: parseFloat(
                    dose.insulinSensitivityFactor
                  ),
                  insulinToCarbRatio: parseFloat(dose.insulinToCarbRatio),
                  auditEntries: treatment.auditLog.getAuditEntries(),
                })
              }
            } else {
              if (isNumeric(dose.value)) {
                prescription.doses.push({
                  timepoint: initialDose.timepoint,
                  doseValue: parseFloat(dose.value),
                  auditEntries: treatment.auditLog.getAuditEntries(),
                })
              }
            }
          }
        })

        prescriptions.push(prescription)
      })

      const data = {
        patientNumber: this.patient.patientNumber,
        prescriptions,
        versionReason,
        auditEntries: this.treatmentSettingsInfoAuditEntries,
        currentClientTime: dateTimeZone,
        dosingRegimen: this.newDosingRegimen,
      }

      service
        .updateDosingRegimen(data)
        .then(() => {
          this.state = loadingState.LOAD_SUCCEEDED
          this.$emit('data-updated')
          this.closeDialog()
        })
        .catch(error => {
          this.$log.error(error)
          this.state = loadingState.LOAD_ERRORED
        })
        .finally(() => {
          this.showConfirmDialog = false
        })
    },
  },
}
</script>

<style lang="scss">
.skeleton-loader-no-text-margin-bottom {
  .v-skeleton-loader__text {
    margin-bottom: 0 !important;
  }
}

.dose-input-row {
  background-color: $nn-TB_T98;
  margin: 0 -24px;
  font-size: 14px;

  .v-skeleton-loader__text {
    margin-bottom: 0 !important;
  }
}
</style>
